<template>
	<div class="cart-header">
		<template v-if="currentBasket?.collective">
			<div class="cart-header__wr">
				<div
					class="cart-header__content"
					:class="{'cart-header__content--main': isCartPage}"
				>
					<div class="cart-header__info-wr">
						<ArrowBackIcon
							@click="$router.back()"
							class="cart-header__back"
						/>
						<div class="cart-header__text-wr">
							<h2 class="cart-header__title">{{ currentBasket?.name }}</h2>
							<span class="cart-header__subtitle">Коллективная корзина</span>
						</div>
					</div>
					<button
						v-if="!isCartPage"
						class="cart-header__out-btn"
						@click="togglePopup('deleteBasket')"
					>
						<img
							src="~@/assets/img/icons/out.svg"
							alt="Out"
						>
					</button>
				</div>
				<div class="cart-header__content">
					<h2 class="cart-header__initiator">Инициатор</h2>
					<span class="cart-header__initiator-name">{{ currentBasket?.ownerName }}</span>
				</div>
				<CartOrderInfo
					:title="currentBasket?.address"
					:subtitle="convertCartOrderInfoToText({
					timeRange: currentBasket?.timeRange,
					day: currentBasket?.day,
					plannedDeliveryTime: currentBasket?.plannedDeliveryTime
				})"
				/>
				<button
					class="cart-header__button"
					v-if="currentBasket?.owner"
					@click="togglePopup('inviteUser')"
				>
					<img
						src="~@/assets/img/icons/plus.svg"
						alt="Plus"
					>
					Добавить участников
				</button>
			</div>
		</template>
		<template v-else>
			<div class="cart-header__content-wr">
				<div
					class="cart-header__content"
					:class="{'cart-header__content--main': isCartPage}"
				>
					<div class="cart-header__info-wr">
						<ArrowBackIcon
							@click="$router.back()"
							class="cart-header__back"
							:style="{marginBottom: 0}"
						/>
						<h2 class="cart-header__title">Корзина</h2>
					</div>
				</div>
				<div
					v-if="showCollective"
					class="cart-header__button-wr"
				>
					<button
						class="cart-header__button"
						@click="handleCreateBasket"
					>
						<img
							class="cart-header__collective"
							src="~@/assets/icons/collective-price.svg"
							alt="Bag"
						>
						Создать коллективную корзину
					</button>
					<!--				На первое время решили убрать это уведомление, в последующем будем показывать-->
					<!--				<CreateBasketInfo-->
					<!--					:class="['cart-header__basket-info', {'cart-header__basket-info&#45;&#45;show': createBasketInfoPopup}]"-->
					<!--					@closeBasketInfo="createBasketInfoPopup = false"-->
					<!--				/>-->
				</div>
			</div>
		</template>
		<CreateBasketFormPopup
			:visible="popups.createBasket"
			@openInvitePopup="togglePopup('inviteUser');"
			@closeForm="togglePopup('createBasket');"
		/>
		<ConfirmationPopup
			:visible="popups.deleteBasket"
			:title="'Вы уверены, что хотите выйти из коллективной корзины?'"
			:subtitle="'Придётся очистить корзину. Отменить переход будет нельзя'"
			@closePopup="togglePopup('deleteBasket')"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="togglePopup('deleteBasket')"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="getDeleteAction"
				>Выйти из корзины
				</button>
			</template>
		</ConfirmationPopup>
		<ConfirmationPopup
			:visible="popups.inviteUser"
			:title="'Поделитесь корзиной с другими участниками'"
			:subtitle="'Отправьте ссылку или покажите QR-код другим участникам, чтобы они могли присоединиться к корзине'"
			@closePopup="togglePopup('inviteUser')"
		>
			<template #content>
				<div
					v-if="currentBasket?.inviteQr"
					class="confirmation-popup__qr"
					v-html="currentBasket?.inviteQr"
				/>
				<yandex-share
					class="cart-header_yandex-share"
					:services="['telegram', 'odnoklassniki', 'vkontakte','whatsapp','viber']"
					:url="currentBasket?.inviteLink"
					:big="true"
					:title="inviteLinkTitle"
				/>
			</template>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn"
					@click="copyLink"
				>
					<img
						src="~@/assets/img/icons/link.svg"
						alt="Link"
					>
					Поделиться ссылкой
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {redirectBasedOnTimeRange, convertCartOrderInfoToText, mobileCheck} from "@/helpers";
import {notification} from "@/utils";
import YandexShare from '@cookieseater/vue-yandex-share';
import ArrowBackIcon from "icons/24/arrow-back.svg?inline";

export default {
	name: "CartHeader",
	props: {
		cartPage: {
			type: Boolean,
			default: false
		},
		collective: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data()
	{
		return {
			popups: {
				createBasket: false,
				deleteBasket: false,
				inviteUser: false
			},
			createBasketInfoPopup: false,
		}
	},
	components: {
		CreateBasketFormPopup: () => import("../popup/CreateBasketFormPopup.vue"),
		ConfirmationPopup: () => import("../popup/ConfirmationPopup.vue"),
		CartOrderInfo: () => import("ui-kit/CartOrderInfo.vue"),
		CreateBasketInfo: () => import("ui/CreateBasketInfo.vue"),
		YandexShare,
		ArrowBackIcon
	},
	computed: {
		...mapGetters({
			currentBasket: "getCurrentBasket",
			user: "getUser",
			cartTimeRange: "getCartTimeRange",
			showCollective: 'getShowCollective',
		}),
		isCartPage()
		{
			return this.cartPage && !mobileCheck('width')
		},
		inviteLinkTitle()
		{
			const basketName = this.currentBasket?.name ? `«${this.currentBasket?.name}»` : '';
			const basketDate = this.currentBasket?.day ? `Дата ${this.currentBasket?.day}.` : '';
			const basketInitiator = this.currentBasket?.ownerName ? `Инициатор ${this.currentBasket?.ownerName}.` : '';

			return `Присоединяйтесь к коллективной корзине ${basketName}. ${basketDate} ${basketInitiator}`;
		}
	},
	methods: {
		...mapActions({
			deleteUser: "deleteUser",
			deleteBasketType: "deleteBasketType",
			getBasketTypes: "getBasketTypes",
			resetCartToDefault: "resetCartToDefault",
			getCart: "getCart",
		}),
		...mapMutations({
			openPhoneAuthPopup: "openPhoneAuthPopup"
		}),
		convertCartOrderInfoToText,
		handleCreateBasket()
		{
			if (!this.user)
			{
				sessionStorage.setItem('reopenCreateBasket', 'true');
				return this.openPhoneAuthPopup();
			}

			this.togglePopup('createBasket');
		},
		copyLink()
		{
			if (!this.currentBasket?.inviteLink) return notification({
				title: 'Что-то пошло не так. Попробуйте ещё раз',
				type: 'error'
			});

			navigator.clipboard.writeText(this.currentBasket?.inviteLink);

			this.togglePopup('inviteUser');

			notification({title: 'Ссылка скопирована, отправьте ее другим участникам'});
		},
		togglePopup(value)
		{
			this.popups[value] = !this.popups[value];
		},
		async getDeleteAction()
		{
			if (this.currentBasket?.owner)
			{
				await this.deleteBasketType(this.currentBasket?.id)
			} else
			{
				await this.deleteUser({
					userId: this.user?.ID,
					cartTypeId: this.currentBasket?.id
				});
			}

			await this.resetCartToDefault();

			await this.getCart();

			await redirectBasedOnTimeRange(this.cartTimeRange);

			this.togglePopup('deleteBasket');
		},
		openCreateBasketInfoPopup()
		{
			this.createBasketInfoPopup = true;
			sessionStorage.setItem('createBasketInfoPopup', 'opened');
		},
	},
	async mounted()
	{
		await this.getBasketTypes();

		if (sessionStorage.getItem('reopenCreateBasket'))
		{
			sessionStorage.removeItem('reopenCreateBasket');
			
			if (!this.currentBasket?.collective) this.togglePopup('createBasket');
		}

		if (!sessionStorage.getItem('createBasketInfoPopup')) this.openCreateBasketInfoPopup();
	},
}
</script>
<style lang="scss">
.cart-header
{
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;

	&__collective {
		width: 32px;
		height: 32px;
	}
}

.cart-header__wr
{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.cart-header__content-wr
{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.cart-header__content
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.cart-header__content--main
{
	border-bottom: 1px solid $greyDelimiter;
	padding-bottom: 8px;
	margin-bottom: 16px;
}

.confirmation-popup__qr
{
	align-self: center;
	max-width: 196px;
	max-height: 196px;

	svg
	{
		width: 100%;
		height: 100%;
	}
}

.cart-header_yandex-share
{
	.ya-share2__container
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ya-share2__badge
	{border-radius: 100% !important;}

	.ya-share2__icon
	{
		width: 48px !important;
		height: 48px !important;
		background-size: 36px !important;
		background-repeat: no-repeat;
		background-position: center;
	}

	.ya-share2__item
	{margin-right: 8px !important;}
}

.cart-header__info-wr
{
	display: flex;
	align-items: flex-start;
	gap: 8px;
}

.cart-header__back
{
	display: none;
	margin-top: 4px;
}

.cart-header__text-wr
{
	display: flex;
	flex-direction: column;
}

.cart-header__title
{
	word-break: break-all;
	font-size: 24px;
	line-height: 32px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.cart-header__initiator
{
	font-weight: 400;
	font-size: 14px;
	line-height: 114%;
	font-family: $mainFontNew;
	color: $textColor;
}

.cart-header__initiator-name
{
	font-weight: 700;
	font-size: 14px;
	line-height: 114%;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.cart-header__subtitle
{
	font-size: 12px;
	line-height: 16px;
	font-family: $mainFontNew;
	color: $greyText;
}

.cart-header__out-btn
{
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: $greyBackground;
	border: none;
}

.cart-header__button-wr
{
	position: relative;
	width: 100%;
}

.cart-header__button
{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 16px;
	padding: 16px 24px;
	background-color: $lightGreenBackground;
	border-radius: 8px;
	border: none;

	font-family: $mainFontMediumNew;
	color: $green;
	font-size: 14px;
	line-height: 20px;
	text-align: start;
}

.cart-header__basket-info
{
	position: absolute;
	top: calc(100% + 18px);
	left: 50%;
	transform: translateX(-50%);

	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.cart-header__basket-info--show
{
	visibility: visible;
	opacity: 1;
}

.cart-header__basket-info::after
{
	content: '';

	position: absolute;
	width: 40px;
	height: 33px;
	right: 53px;
	top: -30px;

	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='23' viewBox='0 0 33 23' fill='none'%3E%3Cg clip-path='url(%23clip0_8892_5375)'%3E%3Cpath d='M1.00535e-06 22.9998L33 22.9998C26.23 22.9998 12.616 -1.84718 2.848 0.109818C0.578 0.563818 8.386 22.9998 1.00535e-06 22.9998Z' fill='%233D4248'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8892_5375'%3E%3Crect width='23' height='33' fill='white' transform='matrix(4.37114e-08 1 1 -4.37114e-08 0 0)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

@media (max-width: 1000px)
{
	.cart-header__back
	{display: block;}

	.cart-header__content--main
	{
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.confirmation-popup__qr
	{
		max-width: 160px;
		max-height: 160px;
	}

	.cart-header_yandex-share
	{
		.ya-share2__item
		{margin-right: 6px !important;}
	}
}
</style>