<!-- Компонент карточки блюда --->
<template>
	<observer @on-change="handleObserverChange" class="observer">
		<div class="meal-list-item">
			<div class="meal-list-item__content">
				<div
					class="meal-list-item__header"
					:style="{ backgroundColor: imgBackground }"
				>
					<MealListItemSlider
						:imgs="imagesSrc"
						:imageObjectFit="imageObjectFit"
						@select="$emit('selected')"
						class="meal-list-item__slider"
					/>

					<div
						class="meal-list-item__discount"
						v-if="discount && discount > 0"
					>
						-{{ discount }}%
					</div>
				</div>

				<div class="meal-list-item__properties">
					<div class="meal-list-item__nutrition">
						{{ type.name }} • {{ weight }}гр
					</div>

					<div
						class="meal-list-item__rating"
						v-if="false"
					>
						<img :src="require('@/assets/img/icons/star.svg')"/>
						({{ rating }})
					</div>
				</div>

				<div class="meal-list-item__title">
					<div
						class="meal-list-item__name"
						@click="$emit('selected')"
						v-html="name"
					/>
					<div class="meal-list-item__badges">
						<div class="meal-list-item__badges-info">
							<img
								:src="require('@/assets/img/icons/eco.svg')"
								v-if="false"
							/>
							<!-- <img :src="require('@/assets/img/icons/info.svg')" width="18" height="18"/>
								<Tooltip
									class="meal-list-item__badges-popup"
									:nutrition="tooltipData"
									:info="false"
									:list="false"
									:reverseArrow="true"
									v-if="showTooltip"
									@click.native="showTooltip = false"
								/>																																																																																																																										/> -->
							<div class="meal-list-item__info-item">
								<svg
									width="18"
									height="19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9 .5a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 1.8a7.2 7.2 0 1 1 0 14.4A7.2 7.2 0 1 1 9 2.3ZM8.1 5v1.8h1.8V5H8.1Zm0 3.6V14h1.8V8.6H8.1Z"
										fill="#979797"
									/>
								</svg>
							</div>
							<Tooltip
								:nutrition="tooltipData"
								:info="false"
								:list="false"
								class="meal-list-item__badges-popup"
								@click.native="showTooltip = false"
							/>
						</div>
					</div>
				</div>

				<div class="meal-list-item__composition">
					{{ composition }}
				</div>
			</div>
			<div class="meal-list-item__bottom">
				<div
					class="meal-list-item__old-price"
					v-if="oldPrice && !collective && (price !== oldPrice)"
				>
					Цена:
					<span class="meal-list-item__old-price__crossed">{{ oldPrice }} ₽</span>
				</div>

				<div
					class="meal-list-item__old-price"
					v-if="collective && (price !== item.collective_price)"
				>
					Цена:
					<span class="meal-list-item__old-price__crossed">{{ price }} ₽</span>
				</div>

				<div class="meal-list-item__current-price-container">
					<div class="meal-list-item__current-price" v-if="!collective">{{ price }} ₽</div>
					<div class="meal-list-item__current-price" v-else>{{ item.collective_price }} ₽</div>
					<div
						class="meal-list-item__bonus"
						v-if="bonus && currentBasket?.owner"
					>
						+{{ bonus }}
						<img :src="require('@/assets/img/icons/bonus.svg')"/>
					</div>
				</div>
				<UiButton
					size="medium"
					@click.prevent="changeCartItem(1)"
					v-if="!count"
					:loading="loading"
				>
					В корзину
				</UiButton>
				<Counter
					v-else
					class="meal-list-item__add-to-cart"
					:value="count"
					:checkCounterBetweenChange="checkCartBeforeAddProduct"
					@change="changeCartItem"
				/>
				<div
					class="meal-list-item__in-app-price"
					v-if="inAppPrice"
				>
					В приложении: {{ inAppPrice }}₽
				</div>
			</div>

			<div class="meal-list-item__collective-price" v-if="!collective && item.collective_price !== price">
				<img src="@/assets/icons/collective-price.svg" alt="">
				<span>{{ item.collective_price }} ₽</span> в коллективной корзине
			</div>
		</div>
	</observer>
</template>

<script>
import Tooltip from "@/components/block/Tooltip.vue";
import Observer from "vue-intersection-observer";
import {mapGetters} from "vuex";
import {useChangeCartItem} from "@/composables";
import {UiButton} from "ui-kit";

export default {
	name: "MealListItem",
	setup()
	{
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading} = useChangeCartItem();

		return {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading};
	},
	components:
		{
			MealListItemSlider: () => import("./MealListItemSlider.vue"),
			Counter: () => import("../../ui/Counter.vue"),
			Tooltip,
			Observer,
			UiButton
		},
	props: {
		item: Object,
		id: Number,
		imgs: Array,
		type: Object,
		nutrition: Object,
		rating: Number,
		name: String,
		badges: Array,
		composition: String,
		weight: Number,
		discount: Number,
		price: Number,
		oldPrice: null,
		inAppPrice: Number,
		bonus: Number,
		imgBackground: {
			type: String,
			default: "#fff",
		},
		collective: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	methods:
		{
			// Отслеживание видимости элемента
			handleObserverChange(entry, unobserve)
			{
				// Если элемент уже был показан на странице, то отключаем отслеживание
				if (entry.isIntersecting)
				{
					unobserve();
				}
				this.imagesSrc = entry.isIntersecting
					? this.imgs
					: [require("../../../assets/img/loading.gif")];
				this.imageObjectFit = entry.isIntersecting ? "contain" : "none";
			},
		},

	data: () => ({
		showTooltip: false,
		requsetTimeout: null,
		imagesSrc: [require("../../../assets/img/loading.gif")],
		imageObjectFit: "none",
	}),
	computed:
		{
			...mapGetters({
				currentBasket: "getCurrentBasket",
			}),
			tooltipData()
			{
				return {
					belki: this.nutrition.proteins,
					jiri: this.nutrition.fats,
					uglevodi: this.nutrition.carbs,
					weight: this.weight,
					calories: this.nutrition.calories
				}
			},
		},
	mounted()
	{
		this.initProduct(this.item);
	}
};
</script>

<style lang="scss">
.meal-list-item__title
{position: relative;}

.meal-list-item__collective-price {
	margin-top: 7px;
	display: flex;
	gap: 0 4px;
	align-items: center;
	width: fit-content;
	text-align: center;
	padding: 2px 8px;
	font-family: $mainFontMediumNew;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #2D2F32;
	border-radius: 6px;
	background-color: #F9F6B9;

	span {
		font-weight: 700;
		font-size: 16px;
	}
}

.meal-list-item__badges-info
{
	position: absolute;
	right: 0;
	top: 0;
	line-height: 0;

	.meal-list-item__info-item
	{
		display: inline-block;
	}

	.meal-list-item__info-item:hover
	{
		svg
		{
			g
			{
				fill: $textColor;
				opacity: 1;
			}

			circle
			{stroke: $textColor;}
		}

		+ .meal-list-item__badges-popup
		{
			transform: translateY(0);
			visibility: visible;
			opacity: 1;
			z-index: 10;
		}
	}
}

.meal-list-item__badges-popup
{
	top: -142px;
	right: 0;
	@include ease(0.125s);
	transform: translateY(-5px);
	opacity: 0;
	visibility: hidden;

	.tooltip__arr
	{display: none;}
}

.meal-list-item__badges-popup__arr
{
	position: absolute;
	background-color: $textColor;
	width: 20px;
	height: 20px;
	bottom: -10px;
	left: 98px;
	transform: rotate(45deg);
}

.meal-list-item__badges-popup__row
{
	display: flex;
	justify-content: space-between;
	z-index: 12;

	&:before
	{
		content: "................................................";
		width: 80%;
		overflow: hidden;
		color: #fff;
		margin-top: -1px;
		position: absolute;
		z-index: 11;
		letter-spacing: 2.9px;
		line-height: 0.7;
	}
}

.meal-list-item__badges-popup__title,
.meal-list-item__badges-popup__value
{
	color: #fff;
	font-family: $mainFont;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 100%;
	background-color: $textColor;
	z-index: 12;
}

.meal-list-item__badges-popup__value
{
	color: #fff;
}

.meal-list-item
{
	width: 282px;
	height: 365px;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__content
	{
		display: flex;
		flex-direction: column;
	}

	&__header
	{
		position: relative;
		height: 186px;
		margin-bottom: 6px;
		border-radius: 24px;
		overflow: hidden;
	}

	&__discount
	{
		position: absolute;
		bottom: 12px;
		left: 12px;
		background: $red;
		border-radius: 100px;
		font-size: 14px;
		line-height: 18px;
		color: #fff;
		padding: 2px 6px;
		font-family: $mainFontBoldNew;
	}

	&__img
	{
		width: 100%;
		object-fit: contain;
		height: inherit;
		cursor: pointer;
	}

	&__slider
	{
		width: inherit;
		height: inherit;
		cursor: pointer;
	}

	&__properties
	{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__nutrition
	{
		font-size: 12px;
		line-height: 12px;
		color: $greyLightText;
		margin-bottom: 8px;
		margin-top: 2px;
	}

	&__rating
	{
		display: flex;
		align-items: center;
		color: $greyLightText;
		font-size: 12px;
		line-height: 12px;

		img
		{margin-right: 5px;}
	}

	&__title
	{
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}

	&__name
	{
		font-size: 18px;
		font-family: $mainFontBoldNew;
		margin-bottom: 8px;
		width: 90%;

		/* Ограничивает текст до двух строк */
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__badges
	{
		display: flex;

		&-info
		{
			position: relative;

			.tooltip
			{
				top: -140px;
				z-index: 1;
			}
		}

		img
		{
			margin-left: 4px;
		}
	}

	&__composition
	{
		font-size: 12px;
		color: $greyLightText;
		line-height: 16px;
		margin-bottom: 6px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		/* Ограничивает текст до двух строк */
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__bottom
	{
		margin-top: auto;
		width: 100%;
		flex-wrap: wrap;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__old-price
	{
		width: 100%;
		font-size: 12px;
		grid-area: old-price;

		&__crossed
		{
			font-family: $mainFontBoldNew;
			color: $greyLightText;
			text-decoration-line: line-through;
		}
	}

	&__current-price-container
	{
		display: flex;
		grid-area: price;
	}

	&__current-price
	{
		margin-right: 10px;
		font-size: 28px;
		font-family: $mainFontBoldNew;
		letter-spacing: -1px;
	}

	&__bonus
	{
		padding: 0 7px 0 4px;
		border-radius: 4px;
		background: $green;
		font-family: $mainFontBoldNew;
		font-size: 14px;
		color: #fff;

		display: flex;
		align-items: center;

		img
		{
			margin-left: 3px;
			margin-top: -2px;
		}
	}

	&__in-app-price
	{
		grid-area: in-app-price;
		min-width: 0;
		border-radius: 4px;
		font-size: 11px;
		line-height: 11px;
		color: $green;
		padding: 2px 4px;
		font-family: $mainFontBoldNew;
		background: $lightGreenBackground;
		justify-self: start;
	}

	&__add-to-cart
	{
		width: 110px;
		height: 40px;
		font-size: 14px;
		grid-area: button;
		justify-self: end;
	}

	@media (max-width: 1439px)
	{
		width: 324px;
		height: 398px;

		&__header
		{height: 225px;}
	}

	@media (max-width: 1100px)
	{
		.meal-list-item
		{
			&__badges
			{
				&-info
				{
					.tooltip
					{left: -210px;}
				}
			}
		}
	}

	@media (max-width: 599px) {
		width: 100%;
		height: auto;
	}

	@media (max-width: 567px)
	{
		&__header
		{
			height: 186px;
		}

		&__name
		{
			width: 80%;
			height: auto;
		}

		&__composition
		{
			height: auto;
		}

		&__badges
		{
			&-info
			{
				.tooltip
				{
					top: -105px;
					z-index: 1;
				}
			}
		}

		&__in-app-price
		{
			display: none;
		}
	}
}
</style>
