<template>
	<div v-if="currentBasket?.collective && currentBasket?.owner">
		<div
			:class="['cart-user', { 'cart-user__cart-page': isCartPage }]"
			v-for="user in basketUsers"
			:key="user?.id"
		>
			<h2
				:class="['cart-user__title', { 'cart-user__title-cart': isCartPage }]"
				v-if="user?.id === basketUsers[0]?.id"
			>
				Ваш заказ
				<span
					v-if="isCartPage"
					class="cart-user__count"
				>
					товаров {{ myCartDetails.count }}
				</span>
			</h2>
			<h2
				:class="['cart-user__title', { 'cart-user__title-cart': isCartPage }]"
				v-if="user?.id === basketUsers[1]?.id"
			>
				Участники
				<span
					v-if="isCartPage"
					class="cart-user__count"
				>
					товаров {{ cartProductsCount - myCartDetails.count || 0 }}
				</span>
			</h2>
			<div
				class="cart-user__user"
				:class="{'cart-user__user-cart': isCartPage}"
			>
				<div class="cart-user__about">
					<div class="cart-user__about-no-avatar">
						<div
							v-if="!isCartPage"
							class="cart-user__open-products-wr"
							@click="toggleInnerBasket(user?.id)"
						>
							<img
								src="~@/assets/img/icons/arrowMini.svg"
								class="cart-user__open-arrow"
								:class="{'cart-user__open-arrow-active': isOpen(user?.id)}"
								alt="Arrow"
							>
						</div>
						{{ user.name ? user.name[0].toUpperCase() : "В&Б" }}
					</div>
					<div class="cart-user__first-middle-name">
						<span class="cart-user__first-name">{{ user?.name }}</span>
						<span
							v-if="user?.isOwner"
							class="cart-user__basket-initiator-text"
						>Инициатор корзины</span>
						<span
							v-else
							class="cart-user__basket-initiator-text"
							:style="{color: user?.done && '$green'}"
						>{{ user?.done ? 'Готов' : 'Собирает' }}</span>
					</div>
				</div>
				<div
					v-if="!isCartPage"
					class="cart-user__basket-actions"
				>
					<img
						v-if="user?.done && !user?.isOwner"
						class="cart-user__basket-confirm"
						src="@/assets/img/icons/choose-icon.svg"
						alt="Choose"
					>
					<h3 class="cart-user__basket-price">{{ cartPrice(user?.id) }} ₽</h3>
				</div>
				<button
					class="cart-user__delete-user-btn btn"
					v-else-if="!user?.isOwner && isCartPage"
					@click="currentUser = user; toggleDeletePopup()"
				>
					<img src="~@/assets/img/icons/trash.svg">
					Удалить участника
				</button>
			</div>
			<div
				class="cart-user__sub-basket-products"
				v-if="isOpen(user?.id)"
			>
				<Component
					:is="mealComponent"
					v-for="meal in filteredMeals(user?.id)"
					:key="meal.id"
					:meal="meal"
					:item="meal.product"
					:productCount="parseInt(meal.count)"
					:collective="collective"
				/>
				<Component
					:is="lunchComponent"
					v-for="lunch in filteredLanches(user?.id)"
					:key="lunch.id"
					:lanch="lunch.lanch"
					:lunch="lunch"
					:collective="collective"
				/>
				<Component
					:is="mealComponent"
					v-for="gift in filteredGifts(user?.id)"
					:key="`MiniCartGift-${gift.id}`"
					:meal="gift"
					:item="gift.product"
					:productCount="parseInt(gift.count)"
					:isGift="true"
					:collective="collective"
				/>
				<button
					class="cart-user__delete-user-btn btn"
					v-if="!user?.isOwner && !isCartPage"
					@click="currentUser = user; toggleDeletePopup()"
				>
					<img src="~@/assets/img/icons/trash.svg">
					Удалить участника
				</button>
			</div>
		</div>
		<div v-if="deletePopup">
			<ConfirmationPopup
				:visible="deletePopup"
				:title="'Удалить участника из коллективной корзины?'"
				@closePopup="toggleDeletePopup"
			>
				<template #buttons>
					<button
						class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
						@click="toggleDeletePopup"
					>Отмена
					</button>
					<button
						class="btn confirmation-popup__btn"
						@click="getDeleteAction"
					>Удалить
					</button>
				</template>
			</ConfirmationPopup>
		</div>
	</div>
	<div v-else>
		<Component
			:is="mealComponent"
			v-for="meal in filteredMeals(userAcc?.ID)"
			:key="meal.id"
			:meal="meal"
			:item="meal.product"
			:productCount="parseInt(meal.count)"
			:collective="collective"
		/>
		<Component
			:is="lunchComponent"
			v-for="lunch in filteredLanches(userAcc?.ID)"
			:key="lunch.id"
			:lanch="lunch.lanch"
			:lunch="lunch"
			:collective="collective"
		/>
		<Component
			:is="mealComponent"
			v-for="gift in filteredGifts(userAcc?.ID)"
			:key="`MiniCartGift-${gift.id}`"
			:meal="gift"
			:item="gift.product"
			:productCount="parseInt(gift.count)"
			:isGift="true"
			:collective="collective"
		/>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {addThousandSeparator, mobileCheck} from "@/helpers";

export default {
	data()
	{
		return {
			openUserProducts: [],
			deletePopup: false,
			currentUser: null,
		}
	},
	props:
		{
			cartPage: {
				type: Boolean,
				default: false
			},
			collective: {
				type: Boolean,
				required: false,
				default: false
			}
		},
	components:
		{
			MiniCartMeal: () => import("./MiniCartMeal.vue"),
			MiniCartLunch: () => import("./MiniCartLunch.vue"),
			ConfirmationPopup: () => import("../popup/ConfirmationPopup.vue"),
			CartMealRow: () => import("../block/CartMealRow.vue"),
			CartLunchRow: () => import("../block/CartLunchRow.vue"),
		},
	computed:
		{
			...mapGetters({
				basketUsers: 'getBasketUsers',
				cartMeals: 'getCartMeals',
				cartLanches: 'getLanches',
				cartGiftes: 'getCartGiftes',
				currentBasket: 'getCurrentBasket',
				myCartDetails: 'getMyCartDetails',
				cartProductsCount: 'getCartProductsCount',
				userAcc: 'getUser',
			}),
			isCartPage()
			{
				return this.cartPage && !mobileCheck('width')
			},
			mealComponent()
			{
				return !this.isCartPage || mobileCheck('width') ? 'MiniCartMeal' : 'CartMealRow'
			},
			lunchComponent()
			{
				return !this.isCartPage || mobileCheck('width') ? 'MiniCartLunch' : 'CartLunchRow'
			},
		},
	methods:
		{
			...mapActions({
				loadBasketUsers: 'loadBasketUsers',
				deleteUser: 'deleteUser',
			}),
			cartPrice(id)
			{
				const meals = this.filteredMeals(id);
				const lunches = this.filteredLanches(id);

				if (!this.collective) {
					const mealsPrice = meals.length ? meals.reduce((acc, meal) => acc + meal.product.price * meal.count, 0) : 0;
					const lunchesPrice = lunches.length ? lunches.reduce((acc, lunch) => acc + lunch.lanch.price * lunch.count, 0) : 0;

					return addThousandSeparator(mealsPrice + lunchesPrice) || 0;
				} else {
					const mealsPrice = meals.length ? meals.reduce((acc, meal) => acc + meal.product.collective_price * meal.count, 0) : 0;
					const lunchesPrice = lunches.length ? lunches.reduce((acc, lunch) => acc + lunch.lanch.collective_price * lunch.count, 0) : 0;

					return addThousandSeparator(mealsPrice + lunchesPrice) || 0;
				}
			},
			async getDeleteAction()
			{
				await this.deleteUser({
					userId: this.currentUser?.id,
					cartTypeId: this.currentBasket?.id
				});

				this.toggleDeletePopup();
			},
			isOpen(id)
			{
				return !this.openUserProducts.includes(id)
			},
			toggleDeletePopup()
			{
				this.deletePopup = !this.deletePopup;
			},
			toggleInnerBasket(id)
			{
				if (this.openUserProducts.includes(id))
				{
					this.openUserProducts = this.openUserProducts.filter(item => item !== id)
				} else
				{
					this.openUserProducts.push(id)
				}
			},
			filteredMeals(id)
			{
				if (!this.cartMeals.length) return [];

				if (!this.currentBasket?.id) return this.cartMeals;

				return this.cartMeals.filter((meal) => meal.user_id === id) || [];
			},
			filteredLanches(id)
			{
				if (!this.cartLanches.length) return [];

				if (!this.currentBasket?.id) return this.cartLanches;

				return this.cartLanches.filter((lanch) => lanch.user_id === id) || [];
			},
			filteredGifts(id)
			{
				if (!this.cartGiftes.length) return [];

				if (!this.currentBasket?.id) return this.cartGiftes;

				return this.cartGiftes.filter((gift) => gift.user_id === id) || [];
			}
		},
	async mounted()
	{
		if (this.currentBasket?.collective) await this.loadBasketUsers(this.currentBasket?.id);
	}
}
</script>

<style lang="scss">
.cart-user
{
	display: flex;
	flex-direction: column;

	padding-bottom: 12px;
	border-bottom: 1px solid $greyBorder;
}

.cart-user__cart-page
{
	padding-bottom: 0;
	border-bottom: none;
}

.cart-user__title
{
	margin-bottom: 20px;

	font-family: $mainFontBoldNew;
	line-height: 24px;
	font-size: 16px;
	font-weight: 500;
}

.cart-user__title-cart
{
	font-size: 20px;
	margin-bottom: 16px;
}

.cart-user__count
{color: $green;}

.cart-user__user
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-user__user-cart
{
	background-color: $greyBackground;
	padding: 6px 16px;
	border-radius: 16px;
}

.cart-user__about
{
	display: flex;
	align-items: center;
}

.cart-user__about-no-avatar
{
	position: relative;
	border-radius: 16px;
	line-height: 24px;
	font-size: 14px;
	font-weight: 500;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: $green;
}

.cart-user__open-products-wr
{
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 50%;
	left: -12px;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: $greyBackground;

	cursor: pointer;
}

.cart-user__open-arrow
{
	transition: transform .3s ease;
	transform: rotate(-90deg);
}

.cart-user__open-arrow-active
{transform: rotate(0deg);}

.cart-user__first-middle-name
{
	display: flex;
	flex-direction: column;
	margin-left: 16px;
}

.cart-user__basket-initiator-text
{
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #979797;
}

.cart-user__basket-actions
{
	display: flex;
	align-items: center;
}

.cart-user__basket-price
{
	font-family: $mainFontBoldNew;
	margin-left: 8px;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}

.cart-user__sub-basket-products
{
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
}

.cart-user__first-name
{
	font-family: $mainFontBoldNew;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;

	max-width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cart-user__delete-user-btn
{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	background-color: $greyBackground;
	color: $greyLightText;
	padding: 8px 0;

	font-family: $mainFontMediumNew;

	&:hover
	{background-color: $greyBackground;}
}
</style>
