<template>
	<form
		class="create-basket-form"
		@submit.prevent
	>
		<div class="create-basket-form__content">
			<h2 class="create-basket-form__title">Создание коллективной корзины</h2>
			<div class="create-basket-form__label-wr">
				<h3 class="create-basket-form__label">Название корзины</h3>
				<div class="create-basket-form__input-wr">
					<input
						type="text"
						placeholder="Название корзины"
						maxlength="30"
						v-model="basketData.name"
					>
					<img
						alt="clear"
						class="create-basket-form__input-wr-clear"
						src="~@/assets/img/close.svg"
						@click="basketData.name = ''"
					/>
				</div>
			</div>
			<div class="create-basket-form__label-wr">
				<h3 class="create-basket-form__label">Адрес доставки:</h3>
				<SwitchLine
					class="create-basket-form__switch"
					@itemChanged="switchDay"
					:selectedItem="currentDayStr"
					:items="DAY_OPTIONS"
				/>
				<SearchMainPopup
					class="create-basket-form__search-address"
					:isAvailable="notificationType"
					:onlyClosePopup="true"
				/>
			</div>
			<div class="create-basket-form__label-wr">
				<h3 class="create-basket-form__label">Ресторан:</h3>
				<ChoiceRestaurants
					@changeRest="changeRest"
					:activeRestaurantid="currentRestaurantId"
				/>
			</div>
			<div
				class="create-basket-form__label-wr"
				v-if="formattedRestDeliveryTime.length"
			>
				<h3 class="create-basket-form__label">Тип меню:</h3>
				<SwitchLine
					class="create-basket-form__switch"
					@itemChanged="switchTimeRange"
					:selectedItem="basketData.timeRange"
					:items="formattedRestDeliveryTime"
				/>
			</div>
			<div class="create-basket-form__label-wr">
				<h3 class="create-basket-form__label">Планируемое время доставки:</h3>
				<DateSelect
					class="create-basket-form__date"
					@change="changeTime"
				/>
			</div>
			<div class="create-basket-form__btn-wr">
				<button
					class="create-basket-form__btn btn"
					@click.prevent="createBasket"
				>
					Создать корзину
				</button>
			</div>
		</div>
		<Map
			class="create-basket-form__map"
			:mapid="'createBasketFromMap'"
			:isAvailable="notificationType"
			@coords="checkCoords"
		/>
	</form>
</template>

<script>
import Map from "@/components/block/Map.vue";
import SearchMainPopup from "@/components/form/SearchMainPopup.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SwitchLine from "@/components/ui/SwitchLine.vue";
import ChoiceRestaurants from "@/components/cart/ChoiceRestaurants.vue";
import DateSelect from "@/components/form/DateSelect.vue";
import search from "@/mixins/search";
import {convertTimeInterval, convertTextToDate, redirectBasedOnTimeRange, errorOutput} from "@/helpers";
import {TIME_RANGES} from "@/constants";
import {notification} from "@/utils";

const DAY_OPTIONS = [
	{name: 'Сегодня', value: 'today'},
	{name: 'Завтра', value: 'tomorrow'},
]

export default {
	name: "CreateBasketForm",
	components: {DateSelect, ChoiceRestaurants, SwitchLine, Map, SearchMainPopup},
	mixins: [search],
	data()
	{
		return {
			DAY_OPTIONS,
			basketData:
				{
					name: 'Работа',
					day: '',
					address: '',
					'coords[0]': '',
					'coords[1]': '',
					restaurantId: '',
					timeRange: '',
					plannedDeliveryTime: '',
				}
		}
	},
	computed: {
		...mapGetters({
			notificationType: 'getMapPopupNotificationType',
			timeRangesWithMenu: 'getTimeRangesWithMenu',
			currentDayStr: 'currentDayStr',
			currentRestaurantId: "getRestarauntId",
			cartTimeRange: "getCartTimeRange",
			restDeliveryTime: "restaurant/getRestDeliveryTime",
			addressMap: 'getAddress',
			coords: 'getCoords',
			currentCityId: "currentCityId",
		}),
		formattedRestDeliveryTime()
		{
			if (!this.restDeliveryTime || !this.timeRangesWithMenu.length) return [];

			const deliveryTimes = Object.entries(this.restDeliveryTime).map(([value, timeInterval]) => ({
				name: TIME_RANGES[value],
				subtitle: convertTimeInterval(timeInterval),
				value
			}))

			return deliveryTimes.filter(item => this.timeRangesWithMenu.includes(item.value));
		}
	},
	methods:
		{
			...mapActions({
				setDate: "setDate",
				createBasketType: "createBasketType",
				getRestDeliveryTime: "restaurant/getRestDeliveryTime",
				getCart: "getCart",
			}),
			...mapMutations({
				setRestarauntId: "setRestarauntId",
				setDelivery: "order/setDelivery",
				setRestaraunts: "setRestaraunts",
				setTimeRange: "setTimeRange",
				setCurrentBasket: "setCurrentBasket",
			}),
			async switchDay(day)
			{
				await this.setDate(day);
				this.basketData.day = convertTextToDate(day);
			},
			switchTimeRange(timeRange)
			{
				this.setTimeRange(timeRange);
				this.basketData.timeRange = timeRange;
			},
			changeTime(time)
			{
				this.setDelivery({time: time.value});
				this.basketData.plannedDeliveryTime = time.value;
			},
			changeRest(restId)
			{
				this.setRestarauntId(restId);
				this.basketData.restaurantId = restId;
			},
			async checkCoords(value)
			{
				const [lat, long] = value;
				this.basketData["coords[0]"] = lat;
				this.basketData["coords[1]"] = long;

				const restaurants = await this.fetchRestaurants(this.currentCityId, lat, long);

				this.setRestaraunts(restaurants);
			},
			async createBasket()
			{
				try
				{
					const {data} = await this.createBasketType(this.basketData);

					const {success, errors, id} = data;

					if (!success) return errorOutput(errors);

					this.setCurrentBasket({
						...this.basketData,
						...data,
					});
					await this.$cookie.set('basketId', id);

					const path = this.$route.path.split('/')[1];
					if (['menu', 'dinner', 'breakfast', 'rest'].includes(path)) redirectBasedOnTimeRange(this.basketData.timeRange);

					await this.getCart();

					const title = `Корзина ${this.basketData.name || ""} успешно создана`;
					notification({title});

					this.$emit('openInvitePopup');
					this.$emit('closeForm');
				} catch (e)
				{
					throw new Error(e);
				}
			},
			initializeBasketData()
			{
				const defaultBasketData = {
					address: this.addressMap || '',
					day: convertTextToDate(this.currentDayStr) || '',
					'coords[0]': this.coords.lat || '',
					'coords[1]': this.coords.lan || '',
					restaurantId: this.currentRestaurantId || '',
				}

				this.basketData = {...this.basketData, ...defaultBasketData};
			}
		},
	watch: {
		formattedRestDeliveryTime()
		{
			if (!this.formattedRestDeliveryTime?.length) return;

			const defaultTimeRange = this.basketData.timeRange || this.cartTimeRange || '';

			const selectedTimeRange = this.formattedRestDeliveryTime.find(deliveryTime => deliveryTime.value === defaultTimeRange)?.value || '';
			const timeRange = selectedTimeRange || this.formattedRestDeliveryTime[0]?.value || '';

			this.setTimeRange(timeRange);
			this.basketData = {...this.basketData, timeRange};
		}
	},
	async mounted()
	{
		await this.getRestDeliveryTime(this.currentRestaurantId);
		this.initializeBasketData();
	}
}
</script>

<style lang="scss">
.create-basket-form
{
	display: flex;
	overflow: hidden;
}

.create-basket-form__content
{
	width: 600px;
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 32px;
	border-right: 1px solid $greyDelimiter;
}

.create-basket-form__title
{
	font-family: $mainFontNew;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	color: $textColor;
}

.create-basket-form__label-wr
{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.create-basket-form__label
{
	font-family: $mainFontNew;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: $textColor;
}

.create-basket-form__switch
{
	width: 100%;
	height: 56px !important;

	.ui-switch-line__square {
		max-width: calc(100% - 4px);
	}
}

.create-basket-form__date
{
	max-width: 100%;

	.date-select__rows
	{max-height: 162px;}
}

.create-basket-form__input-wr
{
	display: flex;
	align-items: center;
	background: $greyBackground;
	border: 1px solid $greyDelimiter;
	border-radius: 8px;
	padding: 16px;
	height: 56px;

	input
	{
		font-family: $mainFontNew;
		background: $greyBackground;
		outline: none;
		border: none;
		height: 100%;
		width: 100%;
	}

	input::placeholder
	{
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $textColor;
	}

	&-clear
	{
		cursor: pointer;
		width: 24px;
		height: 24px;
	}
}

.create-basket-form__btn-wr
{
	display: flex;
	flex: 1;
	align-items: flex-end;
}

.create-basket-form__btn
{
	width: 100%;
	padding: 16px 0;
}

.create-basket-form__search-address
{
	margin: 0;
	position: relative;

	.search-label
	{
		box-shadow: none;
		background: $greyBackground;
		position: relative;
		margin: 0 !important;
	}

	.search__map_address-input
	{top: 0;}

	.search__btn
	{display: none;}

	.search__map_address-label
	{display: none;}
}

.create-basket-form__map
{
	width: 600px;

	.map
	{border-radius: 0px;}

	.map__controls
	{
		height: auto;
		top: 50%;
		transform: translateY(-50%);
	}

	.map__controls-geolocation
	{margin-top: 32px;}
}

@media (max-width: 1257px)
{
	.create-basket-form
	{flex-direction: column;}

	.create-basket-form__content
	{
		width: 100%;
		border: none;
	}

	.create-basket-form__map
	{display: none;}
}
</style>