<template>
	<div class="mini-cart-lunch">
		<div class="mini-cart-lunch__top">

			<div
				class="mini-cart-lunch__expand -flex-center"
				:class="{collapsed}"
				@click.stop="collapsed = !collapsed"
			>
				<svg
					fill="#fff"
					width="10"
					height="6"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M5 5.92a.83.83 0 0 1-.59-.25L1.08 2.34a.83.83 0 1 1 1.18-1.18L5 3.92l2.74-2.65a.83.83 0 0 1 1.16 1.2L5.58 5.68a.83.83 0 0 1-.58.24Z"
					/>
				</svg>
			</div>
			<img
				class="mini-cart-lunch__img"
				src="@/assets/img/Personal/lunch.webp"
			/>
			<div class="mini-cart-lunch__title">
				<div class="mini-cart-lunch__name">{{ lanch?.name }}</div>
				<div class="mini-cart-lunch__info">{{ lunchesCount }} х {{ mealsCount }} • {{ lunch?.weight }} гр</div>
			</div>
			<img
				src="@/assets/img/close.svg"
				@click.stop="openRemovePopup"
				class="mini-cart-lunch__remove"
			/>
		</div>
		<div class="mini-cart-lunch__middle">
			<div class="mini-cart-lunch__sign">
				<span class="bold">{{ getMiniCartSign }}</span>
				для
				<span class="name"> {{ lunch?.name }}</span>
			</div>
			<transition
				name="mini-cart-lunch__meals-transition"
				v-if="singleMealsCount"
			>
				<div
					class="mini-cart-lunch__meals"
					v-if="!collapsed"
				>
					<div
						class="mini-cart-lunch__meal"
						v-for="(meal, num) in lunch.meals"
						:key="num"
					>
						<img
							class="mini-cart-lunch__meal-img"
							:src="meal.detail_picture?.thumb"
						/>
						<div
							class="mini-cart-lunch__meal-name"
							v-html="meal.name"
						/>
						<div class="mini-cart-lunch__meal-info">
							{{ (meal.belki * .01 * meal.weight).toFixed(0) }} гр / {{
								(meal.jiri * .01 * meal.weight).toFixed(0)
							}} гр / {{ (meal.uglevodi * .01 * meal.weight).toFixed(0) }} гр
						</div>
					</div>
				</div>
			</transition>
			<transition
				name="mini-cart-lunch__meals-transition"
				v-else-if="comboMealsCount"
			>
				<div
					class="mini-cart-lunch__meals"
					v-if="!collapsed"
				>
					<div
						v-for="(meals, num) in lunch.lanches"
						:key="num"
					>
						<span class="mini-cart-lunch__meal-title">{{ meals.lanch.name }}</span>
						<div
							v-for="(meal, i) in meals.meals"
							:key="i"
							class="mini-cart-lunch__meal"
						>
							<img
								class="mini-cart-lunch__meal-img"
								:src="meal.detail_picture?.thumb"
							/>
							<div
								class="mini-cart-lunch__meal-name"
								v-html="meal.name"
							/>
							<div class="mini-cart-lunch__meal-info">
								{{ (meal.belki * .01 * meal.weight).toFixed(0) }} гр / {{
									(meal.jiri * .01 * meal.weight).toFixed(0)
								}} гр / {{ (meal.uglevodi * .01 * meal.weight).toFixed(0) }} гр
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
		<div class="mini-cart-lunch__bottom">
			<Counter
				class="mini-cart-lunch__counter"
				:value="+lunch.count"
				:checkCounterBetweenChange="checkCartBeforeAddProduct"
				@change="changeCartItem"
			/>
			<div class="mini-cart-lunch__price-for-one">
				{{ collective ? lanch?.collective_price : lanch?.price || 0 }} ₽/шт
			</div>
			<div class="mini-cart-lunch__price">
				<div class="mini-cart-lunch__rub">{{ lucnhPrice }} ₽</div>
			</div>
		</div>
		<ConfirmationPopup
			:visible="addPopupVisible"
			:title="`Вы уверены, что хотите удалить комбо “${lunch.name || ''}” из корзины?`"
			@closePopup="addPopupVisible = false"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="changeCartItem(0)"
				>Удалить
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="addPopupVisible = false"
				>Отмена
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>
<script>
import Counter from '../ui/Counter.vue';
import {addThousandSeparator, getMealEnding, getLunchEnding} from '@/helpers';
import qs from 'qs';
import {mapActions, mapGetters} from "vuex";
import {useChangeCartItem} from "@/composables";

export default {
	components: {Counter, ConfirmationPopup: () => import('@/components/popup/ConfirmationPopup.vue')},
	name: 'MiniCartLunch',
	setup()
	{
		const {checkCartBeforeAddProduct} = useChangeCartItem();

		return {checkCartBeforeAddProduct};
	},
	data: () => ({
		collapsed: true,
		addPopupVisible: false,
	}),
	props: {
		lanch: Object,
		lunch: Object,
		collective: {
			type: Boolean,
			reqired: false,
			default: false
		}
	},
	computed: {
		...mapGetters({
			currentBasket: 'getCurrentBasket',
		}),
		getMiniCartSign()
		{
			// Пока переделали на Комбо, в последующем будут Ланчи\Ужины\Завтраки, но пока бэк не готов
			return "Комбо";

			// const {businessMealType} = this.$store.state;
			//
			// return businessMealType === "lunch" ? "Ланч" : "Ужин";
		},
		mealsCount()
		{
			let count = (this.lanch.persons_count > 1 ? this.comboMealsCount : this.singleMealsCount);
			return `${count} блюд${getMealEnding(count)}`
		},
		lunchesCount()
		{
			const {businessMealType} = this.$store.state;
			const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

			return `${this.lunch.count} ${mealText}${getLunchEnding(this.lunch.count)}`
		},
		// количество блюд в обычном ланче
		singleMealsCount()
		{
			return this.lunch.meals.length;
		},
		comboMealsCount()
		{
			return this.lanch.types.length * this.lunch.lanches.length;
		},
		lucnhPrice()
		{
			if (!this.collective) {
				const priceForOne = this.lanch?.price || 0;
				const price = priceForOne * this.lunch.count;

				return addThousandSeparator(price);
			} else {
				const priceForOne = this.lanch?.collective_price || 0;
				const price = priceForOne * this.lunch.count;

				return addThousandSeparator(price);
			}
		}
	},
	methods:
		{
			...mapActions({
				getCart: "getCart",
			}),
			openRemovePopup()
			{
				if (!this.checkCartBeforeAddProduct()) return;

				return this.addPopupVisible = !this.addPopupVisible;
			},
			async changeCartItem(count)
			{
				try
				{
					const params = qs.stringify({
						'cartLanchId': this.lunch?.id,
						'cartId': this.lunch?.cart_id,
						'count': count,
						'cartTypeId': this.currentBasket?.id
					});

					const {data} = await this.$axios.post('/cart/setLanchCount/', params);

					if (data.success) await this.getCart();
				} catch (error)
				{
					throw new Error(error)
				}
			},
		},
}
</script>

<style lang="scss">
.mini-cart-lunch__top
{
	margin-left: 12px;
	position: relative;
	display: flex;
	margin-bottom: 8px;
}

.mini-cart-lunch__expand
{
	position: absolute;
	cursor: pointer;
	top: calc(50% - 12px);
	left: -12px;
	width: 24px;
	height: 24px;
	background: $green;
	border-radius: 100%;
	transition: transform .3s ease;

	&.collapsed
	{transform: rotate(-90deg);}
}

.mini-cart-lunch__title
{width: 100%;}

.mini-cart-lunch__name,
.mini-cart-lunch__meal-title
{
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	font-family: $mainFontNew;
}

.mini-cart-lunch__meal-title
{
	display: block;
	margin-bottom: 8px;
}

.mini-cart-lunch__info
{
	font-size: 12px;
	line-height: 16px;
	color: $greyLightText;
}

.mini-cart-lunch__img
{
	padding: 7px;
	background: linear-gradient(212.22deg, #FFCCF4 19.5%, #B6FFF2 78.64%), #F8F8F8;
	border: 1px solid $greyBorder;
	border-radius: 20px;
	object-fit: cover;
	width: 40px;
	height: 40px;
	min-width: 40px;
	margin-right: 12px;
}

.mini-cart-lunch__remove
{
	height: 24px;
	width: 24px;
	cursor: pointer;
}

.mini-cart-lunch__sign
{
	font-size: 14px;
	color: $greyLightText;
	margin-bottom: 10px;

	.bold
	{
		font-size: 16px;
		font-family: $mainFontBoldNew;
		color: $textColor;
	}

	.name
	{color: $green;}
}

.mini-cart-lunch__meals
{
	margin-bottom: 10px;
	transform-origin: top left;

	&-transition-enter-active,
	&-transition-leave-active
	{
		transition: all .3s ease;
		will-change: height;
	}

	&-transition-enter,
	&-transition-leave-to
	{
		max-height: 0;
		transform: scaleY(0);
	}

	&-transition-enter-to,
	&-transition-leave
	{
		max-height: 100vh;
		transform: scaleY(1);
	}
}

.mini-cart-lunch__meal
{
	display: grid;
	grid-template-columns: 38px auto;
	grid-template-rows: auto auto;
	gap: 0px 8px;
	grid-template-areas:
			"img name"
			"img info";

	padding-bottom: 8px;
	margin-bottom: 8px;
	border-bottom: 1px dashed $greyLightText;

	&-img
	{
		grid-area: img;
		object-fit: cover;
		width: 38px;
		height: 38px;
	}

	&-name
	{
		grid-area: name;
		font-size: 14px;
		line-height: 14px;
		font-family: $mainFontMediumNew;
		align-self: start;
	}

	&-info
	{
		grid-area: info;
		font-size: 10px;
		line-height: 10px;
		color: $greyLightText;
		align-self: start;
		margin-top: -4px;
	}

	&:last-child
	{margin-bottom: 20px;}
}

.mini-cart-lunch__bottom
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mini-cart-lunch__counter
{width: 102px;}

.mini-cart-lunch__price
{
	display: flex;
	align-items: center;
}

.mini-cart-lunch__rub
{
	font-size: 20px;
	font-family: $mainFontBoldNew;
	letter-spacing: -1px;
}

.mini-cart-lunch__bonus
{
	background: $green;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	border-radius: 4px;
	width: 52px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;

	img
	{
		margin-left: 3px;
		margin-top: -2px;
	}
}

.mini-cart-lunch__price-for-one
{
	background-color: $greyBackground;
	border-radius: 2px;
	padding: 4px 6px;
	font-size: 14px;
	line-height: 20px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}
</style>
