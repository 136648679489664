<template>
	<div class="cart-confirm">
		<div
			class="cart-confirm__person-count"
			v-if="currentBasket?.owner"
		>
			<h2 class="cart-confirm__person-title">Количество приборов:</h2>
			<Counter
				:value="+personsCount"
				:max="+maxPersonsCount"
				@change="personsChange"
			/>
		</div>
		<div class="cart-confirm__items">
			<div
				class="cart-confirm__item-row"
				v-if="isMyOrder ? myCartDetails.count : cartProductsCount"
			>
				<span class="cart-confirm__item-info">
					Товары ({{ isMyOrder ? myCartDetails.count : cartProductsCount }} шт)
				</span>
				<span class="cart-confirm__item-info">
					{{ addThousandSeparator(isMyOrder ? myCartDetails.sum : cartSumm) }} ₽
				</span>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="isMyOrder ? myCartDetails.weight : cartTotalWeight"
			>
				<span class="cart-confirm__item-info">Общий вес</span>
				<span class="cart-confirm__item-info">{{
						addThousandSeparator(isMyOrder ? myCartDetails.weight : cartTotalWeight)
					}} гр
				</span>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="isMyOrder ? myCartDetails.kkal : cartKkal"
			>
				<span class="cart-confirm__item-info">Ккал</span>
				<span class="cart-confirm__item-info">{{
						addThousandSeparator(isMyOrder ? myCartDetails.kkal : cartKkal)
					}} Ккал
				</span>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="cartDeliverySum"
			>
				<span class="cart-confirm__item-info">Доставка</span>
				<span class="cart-confirm__item-info">{{ addThousandSeparator(cartDeliverySum) }} ₽</span>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="cartLeftForFreeDeliverySum"
			>
				<span class="cart-confirm__item-info">До бесплатной доставки</span>
				<span class="cart-confirm__item-info">{{ addThousandSeparator(cartLeftForFreeDeliverySum) }} ₽</span>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="cartBalls && currentBasket?.owner"
			>
				<span class="cart-confirm__bonus-title">Баллов начислится:</span>
				<div class="cart-meal-row__total_mark">
					+{{ addThousandSeparator(cartBalls) }}
					<img
						src="~@/assets/img/icons/bonus.svg"
						alt="Bonus"
					/>
				</div>
			</div>
			<div
				class="cart-confirm__item-row"
				v-if="isMyOrder ? myCartDetails.sum : cartSumm"
			>
				<div class="cart-confirm__total-price">Итого</div>
				<div class="cart-confirm__total-price">{{
						addThousandSeparator(isMyOrder ? myCartDetails.sum : cartSumm)
					}} ₽
				</div>
			</div>
		</div>
		<button
			class="cart-confirm__to-checkout btn"
			v-if="!currentBasket?.owner && !isDone && myCartDetails.count"
			@click="userFinished()"
		>
			Завершить сборку
		</button>
		<span
			v-else-if="!currentBasket?.owner && isDone"
			class="cart-confirm__finished"
		>
			Вы закончили сборку
		</span>
		<button
			class="cart-confirm__to-checkout btn"
			@click="openConfirmOrderingPopup"
			v-else-if="currentBasket?.owner && cartSumm || !currentBasket"
		>
			Перейти к оформлению
		</button>
	</div>
</template>

<script>
import Counter from '@/components/ui/Counter.vue'
import {mapActions, mapGetters} from "vuex";
import {addThousandSeparator} from "@/helpers";

export default {
	components: {Counter},
	computed:
		{
			...mapGetters({
				cartSumm: 'getCartSumm',
				cartBalls: 'getCartBalls',
				cartMeals: 'getCartMeals',
				cartKkal: 'getCartKkal',
				cartTotalWeight: 'getCartTotalWeight',
				cartDeliverySum: "getCartDeliverySum",
				cartLeftForFreeDeliverySum: "getCartLeftForFreeDeliverySum",
				cartProductsCount: 'getCartProductsCount',
				myCartDetails: 'getMyCartDetails',
				maxPersonsCount: 'getMaxPersonsCount',
				currentBasket: 'getCurrentBasket',
				isDone: 'getDone',
				basketUsers: 'getBasketUsers',
				personsCount: 'getPersonsCount',
				isMyOrder: 'getIsMyOrder',
			}),
		},
	methods:
		{
			...mapActions({
				fetchChangeDeviceQuantity: 'fetchChangeDeviceQuantity',
			}),
			addThousandSeparator,
			openConfirmOrderingPopup()
			{
				this.$emit('openConfirmOrderingPopup');
			},
			userFinished()
			{
				this.$emit('openUserFinishedPopup');
			},
			async personsChange(count)
			{
				await this.fetchChangeDeviceQuantity(count)
			},
		},
}
</script>

<style lang="scss">
.cart-confirm
{
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 40px;
	border-radius: 24px;
	border: 1px solid #F0F0F0;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.cart-confirm__to-checkout
{
	cursor: pointer;
	text-align: center;
	padding: 16px 40px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	width: 100%;
}

.cart-confirm__items
{
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.cart-confirm__item-info
{
	font-size: 16px;
	line-height: 24px;
	font-family: $mainFontNew;
	color: $textColor;
}

.cart-confirm__bonus-title
{color: #40AE49;}

.cart-confirm__person-title
{
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #3D4248;
}

.cart-confirm__total-price
{
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	color: #3D4248;
}

.cart-confirm__finished
{
	display: inline-block;
	width: 100%;
	text-align: center;
	color: #979797;
	font-weight: 700;
}

.cart-confirm__person-count
{
	display: flex;
	justify-content: space-between;
}

.cart-confirm__item-row
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
